<template>
  <div class="h-100 d-flex flex-column px-3 justify-content-center">
    <FadeList class="threeColumnGrid align-self-center mb-5">
      <div
        v-for="(draw, i) in previousResults"
        :key="i"
        class="cardDimensions"
      >
        <div class="d-flex justify-content-center w-100 mb-2 primary-colour text-center">
          <div style="font-size:0.8rem;height:30px;">
            {{ convertDate(draw.DateDrawn) }}
          </div>
        </div>
        <CardPreviewFromProps
          :hero="draw.hero"
          :colour="drawColourHex(draw.colour)"
          :sport="draw.sport"
          :number="draw.number"
          :first-initial="drawInitial(draw.letterOne)"
          :second-initial="drawInitial(draw.letterTwo)" 
        />
      </div>
    </FadeList>
  </div>
</template>

<script>
import baseService from '@/api-services/base.service'
import { mapGetters } from 'vuex'
import CardPreviewFromProps from '@/components/cardPreviews/cardPreviewFromProps.vue'
import FadeList from '@/components/UI/fadeList.vue'

export default {
  name: 'AllResults',
  components: {
    CardPreviewFromProps, FadeList
  },
  data () {
    return {
      previousResults: [],
      loading: false,
      userLanguage: navigator.language
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  mounted () {
    this.getAllHeroResults()
  },
  methods: {
    async getAllHeroResults () {
      this.loading = true
      try {
        const res = await baseService.getAllHeroResults()
        this.previousResults = res.data.slice(0, 3)
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    convertDate (dateString) {
      const dateObj = new Date(dateString);
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
      }
      const formattedDate = dateObj.toLocaleDateString([this.userLanguage], options)
      return formattedDate
    },
    drawInitial (number) { 
      let letter = ''
      switch (number) {
        case 1: letter = 'A'; break
        case 2: letter = 'B'; break
        case 3: letter = 'C'; break
        case 4: letter = 'D'; break
        case 5: letter = 'E'; break
        case 6: letter = 'F'; break
        case 7: letter = 'G'; break
        case 8: letter = 'H'; break
        case 9: letter = 'I'; break
        case 10: letter = 'J'; break
        case 11: letter = 'K'; break
        case 12: letter = 'L'; break
        case 13: letter = 'M'; break
        case 14: letter = 'N'; break
        case 15: letter = 'O'; break
        case 16: letter = 'P'; break
        case 17: letter = 'Q'; break
        case 18: letter = 'R'; break
        case 19: letter = 'S'; break
        case 20: letter = 'T'; break
        case 21: letter = 'U'; break
        case 22: letter = 'V'; break
        case 23: letter = 'W'; break
        case 24: letter = 'X'; break
        case 25: letter = 'Y'; break
        case 26: letter = 'Z'; break
      }
      return letter
    },
    drawColourHex (colourNumber) {
      let colour = ''
      switch (colourNumber) {
        case 1: colour = '#ff0000'; break
        case 2: colour = '#0000ff'; break
        case 3: colour = '#ffff00'; break
        case 4: colour = '#008000'; break
        case 5: colour = '#ff9900'; break
        case 6: colour = '#800080'; break
        case 7: colour = '#fa42d2'; break
        case 8: colour = '#ffffff'; break
      }
      return colour
    },
  }
}
</script>

<style scoped>
.threeColumnGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto;
  max-width: 600px;
}
.cardDimensions {
  height: 300px;
  width: 200px;
  max-width: 30vw;
}
</style>
